import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { Link } from 'gatsby';

const Navigation = ({ links }) => {

  const getListItems = (navLinks) => (
    navLinks.map(link => (
      <Link
        key={link.name}
        to={link.url}
        activeClassName={styles.active}
        className={styles.link}
      >
        {link.name}

        <div className={clsx(styles.line, styles.showLessThanTablet)} />
      </Link>
    ))
  );

  return (
    <>
      <nav className={clsx(styles.nav, styles.showLessThanTablet)}>
          <ul className={clsx(styles.list, styles.mobList )}>
            {getListItems(links)}
          </ul>
      </nav>

        <nav className={clsx(styles.nav, styles.showMoreThanTablet)}>
          <ul className={clsx(styles.list)}>
            {getListItems(links)}
          </ul>
      </nav>
    </>
  )
};

Navigation.propTypes = {
  links: PropTypes.array.isRequired
};

export default Navigation;
