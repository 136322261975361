import React from "react";
import styles from "./styles.module.scss";
import AppStoreBadge from "../../assets/icons/app-store-badge.svg";
import GooglePlayBadge from "../../assets/icons/google-play-badge.svg";

const currentYear = new Date().getFullYear();

const Footer = () => {
  const SocialIcon = ({ icon }) => <div className={styles.icon}>{icon}</div>;

  return (
    <footer className={styles.footer}>
      <div className={styles.footerInfo}>
        <p>Copyright {currentYear} © All rights reserved. TappShare, LLC</p>
        <p>Privacy Policy</p>
      </div>
      <div className={styles.footerSocial}>
        <a href="https://apps.apple.com/us/app/speakeasy-personal-promos/id1455972687">
          <SocialIcon icon={<AppStoreBadge />} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=speakeasy.com.speakeasy&hl=en_US&gl=US">
          <SocialIcon icon={<GooglePlayBadge />} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
