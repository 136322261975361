import React from 'react';
import styles from './styles.module.scss';
import Title from '../UI/Title/Title';
import BackgroundImage from '../../assets/images/mobile.svg';
import CreateCampaignButton from '../CreateCampaignButton/CreateCampaignButton';

const TryNowModal = ({ onClickCreate }) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Title
          title="Create a beautiful, mobile friendly campaign"
          variant="h3"
          weight="extra-bold"
          classProp={styles.title}
        />
        <Title
          title="to engage your audience in seconds. Try for free!"
          weight="lighter"
          variant="h4"
          classProp={styles.subTitle}
        />
      </div>
      <CreateCampaignButton onClick={onClickCreate} to="/try-now" shadow="primary-dark" classProp={styles.tryNowBtn}  />
      <BackgroundImage className={styles.bgImage} />
    </div>
  );
};

export default TryNowModal;
