import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import clsx from 'clsx';

// title: button text
// variant:  outlined
// background: primary | primary-dark | cyan | white
// size: small(14px) | medium(16px) | large(21px)
// textColor: primary | primary-dark |white
// shadow: primary | primary-dark
// icon: React node
// onClick: on click event
// disabled

const Button = ({
  onClick,
  variant,
  background,
  size,
  stylesProp,
  classProp,
  textColor,
  title,
  shadow,
  weight,
  icon,
  disabled
 }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{
        ...stylesProp,
      }}
      className={clsx(
        styles.button,
        variant === 'outlined' && textColor === 'primary' ? styles.outlinedPrimary
          : variant === 'outlined' ? styles.outlinedWhite : '',
        background === 'primary' ? styles.backgroundPrimary : '',
        background === 'primary-dark' ? styles.backgroundPrimaryDark : '',
        background === 'white' ? styles.backgroundWhite : '',
        background === 'cyan' ? styles.backgroundPrimaryCyan : '',
        textColor === 'primary' ? styles.textPrimary : '',
        textColor === 'primary-dark' ? styles.textPrimaryDark : '',
        shadow === 'primary' ? styles.shadowPrimary : '',
        shadow === 'primary-dark' ? styles.shadowPrimaryDark : '',
        size === 'small' ? styles.smallSize : '',
        size === 'medium' ? styles.mediumSize : '',
        size === 'large' ? styles.largeSize : '',
        weight === 'bold' ? styles.boldWeight : '',
        weight === 'regular' ? styles.regularWeight : '',
        weight === 'lighter' ? styles.lighterWeight : '',
        icon ? styles.iconButton : '',
        classProp ? classProp : '',
        disabled ? styles.disabled : '',
      )}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {title}
    </button>
  )
};

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.string,
  stylesProp: PropTypes.object,
  classProp: PropTypes.string,
  weight: PropTypes.string,
  iconLeft: PropTypes.node,
  shadow: PropTypes.string
};

export default Button;

