import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import CloseIcon from "../../../assets/icons/close.svg";

const Modal = ({ handleClose, show, children }) => {
  return (
     show && (
      <div className={styles.modal}>
        <div className={styles.modalContainer}>
          <div className={styles.content}>
            <CloseIcon className={styles.close} onClick={handleClose}/>
            {children}
          </div>
        </div>
      </div>
    )
  );
};

Modal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export default Modal;
