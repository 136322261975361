import React from "react";
import styles from "./styles.module.scss";
import "./burger-styles.scss";
import { slide as Menu } from "react-burger-menu";
import Logo from "../../assets/images/logo.svg";
import { Link } from "gatsby";
import Navigation from "../Navigation/Navigation";
import RocketIcon from "../../assets/icons/rocket.svg";
import LockIcon from "../../assets/icons/lock.svg";
import BurgerIcon from "../../assets/icons/burger.svg";
import CloseIcon from "../../assets/icons/close.svg";
import Button from "../UI/Button/Button";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { setTryNowModal } from "../../state/actions/mainActions";

const navLinks = [
  { name: "Home", url: "/" },
  { name: "Why TappShare?", url: "/why-tappshare/" },
  { name: "How it works", url: "/how-it-works/" },
];

const ContactSalesBtn = () => (
  <div className={styles.redirectItem}>
    <RocketIcon className={styles.icon} />
    <Link to="/" className={styles.contactSales}>
      Contact sales
    </Link>
  </div>
);

const Header = () => {
  const dispatch = useDispatch();

  const TryNowBtn = () => (
    <Button
      background="primary"
      title="try now"
      size="small"
      weight="regular"
      classProp={styles.tryNowBtn}
      onClick={() => dispatch(setTryNowModal(true))}
    />
  );

  const SignInBtn = () => (
    <div className={styles.redirectItem} role="button" tabIndex={0}>
      <LockIcon className={styles.icon} />
      <div className={styles.signIn}>Sign in</div>
    </div>
  );

  return (
    <>
      <header className={styles.header}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>

        <div className={styles.showMoreThanTablet}>
          <Navigation links={navLinks} />
          <div
            className={clsx(
              styles.redirectContainer,
              styles.showMoreThanTablet
            )}
          >
            <div className={styles.links}>
              <ContactSalesBtn />
              <SignInBtn />
            </div>
            <TryNowBtn />
          </div>
        </div>

        <div className={styles.showLessThanTablet}>
          <TryNowBtn />
        </div>
      </header>

      <div className={styles.showLessThanTablet}>
        <Menu
          right
          customBurgerIcon={<BurgerIcon />}
          customCrossIcon={<CloseIcon />}
        >
          <SignInBtn />
          <Navigation links={navLinks} />
          <div className={styles.mobileMenuBottom}>
            <ContactSalesBtn />
          </div>
        </Menu>
      </div>
    </>
  );
};

export default Header;
