import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import clsx from 'clsx';


const Title = ({ variant, color, title, stylesProp, classProp, size, weight }) => {
  const classNames = clsx(
    styles.title,
    classProp ? classProp : '',
    variant === 'h1' ? styles.h1 : '',
    variant === 'h2' ? styles.h2 : '',
    variant === 'h3' ? styles.h3 : '',
    variant === 'h4' ? styles.h4 : '',
    variant === 'h5' ? styles.h5 : '',
    variant === 'text' ? styles.text : '',
    color === 'black' ? styles.black : '',
    color === 'white' ? styles.white : '',
    color === 'primary' ? styles.primary : '',
    size === 'large' ? styles.large : '',
    size === 'regular' ? styles.regular : '',
    weight === 'bold' ? styles.bold : '',
    weight === 'extra-bold' ? styles.extraBold : '',
    weight === 'lighter' ? styles.light : ''
  );

  const stylesObj = {...stylesProp};

  return (
    variant === 'h1' ? <h1 className={classNames} style={stylesObj}>{title}</h1> :
    variant === 'h2' ? <h2 className={classNames} style={stylesObj}>{title}</h2> :
    variant === 'h3' ? <h3 className={classNames} style={stylesObj}>{title}</h3> :
    variant === 'h4' ? <h3 className={classNames} style={stylesObj}>{title}</h3> :
    variant === 'h5' ? <h5 className={classNames} style={stylesObj}>{title}</h5> :
    variant === 'text' ? <p className={classNames} style={stylesObj}>{title}</p> :
    <h3>{title}</h3>
  );
};

Title.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  stylesProp: PropTypes.object,
  classProp: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.string,
}

export default Title;
