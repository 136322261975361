
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import clsx from 'clsx';
import Button from '../UI/Button/Button';
import {Link} from 'gatsby';


// shadow: true | false
// classProp: style class
// to: relative url
// onClick

const CreateCampaignButton = ({ classProp, shadow, to, onClick }) => {
  return (
    <Link to={to}>
      <Button
        onClick={onClick}
        title="Create Campaign"
        background="primary-dark"
        shadow={shadow ? 'primary-dark' : ''}
        size="medium"
        classProp={clsx( styles.button, classProp ? classProp : '')}
      />
    </Link>
  )
};

CreateCampaignButton.propTypes = {
  shadow: PropTypes.string,
  classProp: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func
};

export default CreateCampaignButton;
