import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/styles/main.scss';
import Header from '../Header/Header';
import styles from './styles.module.scss';
import Footer from '../Footer/Footer';
import TopLayout from '../../gatsby-theme-material-ui-top-layout/components/top-layout';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import {useDispatch, useSelector} from 'react-redux';
import TryNowModal from '../TryNowModal/TryNowModal';
import Modal from '../UI/Modal/Modal';
import {setTryNowModal} from '../../state/actions/mainActions';

const Layout = ( {children } ) => {
  const dispatch = useDispatch();
  const { tryNowModalOpened } = useSelector((state) => state.mainReducer);

  return (
    <TopLayout>
      <Modal
        show={tryNowModalOpened}
        handleClose={() => dispatch(setTryNowModal(false))}
      >
        <TryNowModal onClickCreate={() => dispatch(setTryNowModal(false))} />
      </Modal>
      <div className={styles.main}>
        <Header />
        <div className={styles.contentWrapper}>
          {children}
          <Footer />
        </div>
      </div>
    </TopLayout>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
